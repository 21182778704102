<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.clone") }}
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            v-model.number="id_warehouse"
            :items="options"
            :label="$t('labels.clone_from_warehouse')"
            outlined
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn
        color="success darken-1"
        text
        @click="create"
        :disabled="!id_warehouse"
      >
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "CloneWarehouseWorkingTime",
  props: {
    updatingItem: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    id_warehouse: null,
    options: [],
    isLoading: false,
  }),
  mounted() {
    this.getWarehouses();
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    getWarehouses() {
      this.options = window.me.warehouses.map((e) => ({
        value: e.id,
        text: e.code_name || "",
      }));
    },
    async create() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/warehouse/v1/clone-warehouse-working-time", {
          id_warehouse: this.updatingItem.id,
          id_warehouse_clone: this.id_warehouse,
        });
        this.$vToastify.success(this.$t("messages.update_success"));
        this.isLoading = false;
        this.$emit("refreshData", true);
        this.cancel();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
